import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "./App.css";

const App: React.FC = () => {
	return (
		<header>
			<h1>
				petern.xyz{" "}
				<span className="wave" role="img" aria-label="wave">
					👋🏼
				</span>
			</h1>
		</header>
	);
};

export default App;
